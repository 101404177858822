// src/components/ServiceProvider/ProviderList.js
import React, { useState, useEffect } from 'react';
import { Search, MapPin, ChevronLeft, ChevronRight, Mail, Phone,  Facebook, Instagram, Youtube  } from 'lucide-react';
import { getProviders } from '../../services/api';
import { SERVICES } from '../../constants/serviceTypes';

const ProviderList = () => {
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    total: 0,
    hasNextPage: false,
    hasPrevPage: false
  });
  const [filters, setFilters] = useState({
    search: '',
    service: '',
    location: '',
    sortBy: 'name'
  });

  useEffect(() => {
    fetchProviders();
  }, [filters, pagination.page]);

  const fetchProviders = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await getProviders({
        ...filters,
        page: pagination.page,
        limit: 9
      });

      if (response && response.status === 'success' && response.data) {
        setProviders(response.data.providers);
        setPagination(response.data.pagination);
      } else {
        setProviders([]);
        setError('No data available');
      }
    } catch (err) {
      setError('Unable to load providers. Please try again.');
      console.error('Error fetching providers:', err);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setPagination(prev => ({ ...prev, page: newPage }));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleFilterChange = (key, value) => {
    setFilters(prev => ({
      ...prev,
      [key]: value
    }));
    setPagination(prev => ({ ...prev, page: 1 }));
  };

  const getActiveServices = (services) => {
    return Object.entries(services)
      .filter(([_, isActive]) => isActive)
      .map(([service]) => SERVICES[service] || service);
  };

  const renderProviderCard = (provider) => (
    <div key={provider._id} className="bg-white rounded-lg shadow-md overflow-hidden">
      {provider.imageUrl && (
        <div className="relative h-48">
          <img
            src={provider.imageUrl}
            alt={`${provider.name}`}
            className="w-full h-full object-contain"
            loading="lazy"
          />
          <div className="absolute top-4 right-4">
            <span className="bg-green-500 text-white px-2 py-1 rounded-full text-sm">
              Verified
            </span>
          </div>
        </div>
      )}
      
      <div className="p-6">
        <h3 className="text-xl font-semibold mb-2">{provider.name}</h3>
        <p className="text-gray-500 text-sm mb-4">
          <MapPin className="inline-block mr-1" size={16} />
          {provider.city}, {provider.state}
        </p>
        
        <div className="mb-4">
          <h4 className="text-sm font-semibold text-gray-600 mb-2">Services Offered:</h4>
          <div className="flex flex-wrap gap-2">
            {getActiveServices(provider.services).map((service, index) => (
              <span
                key={index}
                className="bg-orange-100 text-orange-800 text-xs px-2 py-1 rounded-full"
              >
                {service}
              </span>
            ))}
          </div>
        </div>

        {provider.experience && (
          <div className="mb-4">
            <h4 className="text-sm font-semibold text-gray-600">Experience:</h4>
            <p className="text-gray-600">{provider.experience}</p>
          </div>
        )}

  <div className="mt-4 pt-4 border-t border-gray-200">
      <div className="space-y-2">
        <div className="flex items-center">
          <Mail className="w-4 h-4 text-gray-400 mr-2" />
          <a 
            href={`mailto:${provider.email}`} 
            className="text-sm text-blue-600 hover:text-blue-800"
          >
            {provider.email}
          </a>
        </div>
        <div className="flex items-center">
          <Phone className="w-4 h-4 text-gray-400 mr-2" />
          <a 
            href={`tel:${provider.phone}`} 
            className="text-sm text-blue-600 hover:text-blue-800"
          >
            {provider.phone}
          </a>
        </div>
        
        {/* Social Media Links */}
        {provider.socialLinks && Object.values(provider.socialLinks).some(link => link) && (
          <div className="mt-3 pt-3 border-t border-gray-100">
            <h4 className="text-sm font-semibold text-gray-600 mb-2">Portfolio:</h4>
            <div className="flex items-center space-x-4">
              {provider.socialLinks.facebook && (
                <a 
                  href={provider.socialLinks.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800"
                  aria-label="Facebook"
                >
                  <Facebook className="w-5 h-5" />
                </a>
              )}
              
              {provider.socialLinks.instagram && (
                <a 
                  href={provider.socialLinks.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-pink-600 hover:text-pink-800"
                  aria-label="Instagram"
                >
                  <Instagram className="w-5 h-5" />
                </a>
              )}
              
              {provider.socialLinks.youtube && (
                <a 
                  href={provider.socialLinks.youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-red-600 hover:text-red-800"
                  aria-label="YouTube"
                >
                  <Youtube className="w-5 h-5" />
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
      </div>
    </div>
  );

  const renderLoadingSkeleton = () => (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {[1, 2, 3].map((n) => (
        <div key={n} className="animate-pulse bg-white rounded-lg shadow-md p-6">
          <div className="w-full h-48 bg-gray-200 rounded-lg mb-4" />
          <div className="h-4 bg-gray-200 rounded w-3/4 mb-2" />
          <div className="h-4 bg-gray-200 rounded w-1/2" />
        </div>
      ))}
    </div>
  );

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      {/* Search and Filters */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          {/* Search by name */}
          <div className="relative">
            <Search className="absolute left-3 top-3 text-gray-400" size={20} />
            <input
              type="text"
              placeholder="Search by name..."
              className="w-full pl-10 pr-4 py-2 border rounded-lg"
              value={filters.search}
              onChange={(e) => handleFilterChange('search', e.target.value)}
            />
          </div>

          {/* Location filter */}
          <div className="relative">
            <MapPin className="absolute left-3 top-3 text-gray-400" size={20} />
            <input
              type="text"
              placeholder="Enter location..."
              className="w-full pl-10 pr-4 py-2 border rounded-lg"
              value={filters.location}
              onChange={(e) => handleFilterChange('location', e.target.value)}
            />
          </div>

          {/* Services filter */}
          <select
            className="w-full p-2 border rounded-lg"
            value={filters.service}
            onChange={(e) => handleFilterChange('service', e.target.value)}
          >
            <option value="">All Services</option>
            {Object.entries(SERVICES).map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>

          {/* Sort options */}
          <select
            className="w-full p-2 border rounded-lg"
            value={filters.sortBy}
            onChange={(e) => handleFilterChange('sortBy', e.target.value)}
          >
            <option value="recent">Most Recent</option>
            <option value="name">Name A-Z</option>
            <option value="location">Location</option>
          </select>
        </div>
      </div>

      {/* Error Message */}
      {error && (
        <div className="text-center py-12">
          <div className="text-red-600 mb-4">{error}</div>
          <button
            onClick={fetchProviders}
            className="bg-saffron-600 text-white px-4 py-2 rounded hover:bg-saffron-700"
          >
            Try Again
          </button>
        </div>
      )}

      {/* Provider Grid */}
      {loading ? (
        renderLoadingSkeleton()
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {providers.map(renderProviderCard)}
          </div>

          {/* No Results */}
          {providers.length === 0 && !error && (
            <div className="text-center py-12">
              <h3 className="text-xl font-semibold mb-2">No providers found</h3>
              <p className="text-gray-600 mb-4">Try adjusting your search filters</p>
              <button
                onClick={() => {
                  setFilters({
                    search: '',
                    service: '',
                    location: '',
                    sortBy: 'recent'
                  });
                }}
                className="bg-saffron-600 text-white px-4 py-2 rounded hover:bg-saffron-700"
              >
                Clear Filters
              </button>
            </div>
          )}

          {/* Pagination */}
          {providers.length > 0 && (
            <div className="mt-8 flex items-center justify-between">
              <div className="flex items-center gap-2">
                <p className="text-sm text-gray-700">
                  Showing {((pagination.page - 1) * 9) + 1} to{' '}
                  {Math.min(pagination.page * 9, pagination.total)} of{' '}
                  {pagination.total} providers
                </p>
              </div>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => handlePageChange(pagination.page - 1)}
                  disabled={!pagination.hasPrevPage}
                  className={`p-2 rounded-md ${
                    pagination.hasPrevPage
                      ? 'bg-white text-gray-700 hover:bg-gray-50'
                      : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  }`}
                  aria-label="Previous page"
                >
                  <ChevronLeft size={20} />
                </button>

                <div className="flex items-center gap-1">
                  {[...Array(pagination.totalPages)].map((_, index) => {
                    const pageNumber = index + 1;
                    const isCurrentPage = pageNumber === pagination.page;
                    const shouldShow = 
                      pageNumber === 1 ||
                      pageNumber === pagination.totalPages ||
                      Math.abs(pageNumber - pagination.page) <= 1;

                    if (!shouldShow) {
                      if (pageNumber === 2 || pageNumber === pagination.totalPages - 1) {
                        return <span key={pageNumber} className="px-2">...</span>;
                      }
                      return null;
                    }

                    return (
                      <button
                        key={pageNumber}
                        onClick={() => handlePageChange(pageNumber)}
                        className={`px-4 py-2 rounded-md ${
                          isCurrentPage
                            ? 'bg-saffron-600 text-white'
                            : 'bg-white text-gray-700 hover:bg-gray-50'
                        }`}
                        aria-label={`Page ${pageNumber}`}
                        aria-current={isCurrentPage ? 'page' : undefined}
                      >
                        {pageNumber}
                      </button>
                    );
                  })}
                </div>

                <button
                  onClick={() => handlePageChange(pagination.page + 1)}
                  disabled={!pagination.hasNextPage}
                  className={`p-2 rounded-md ${
                    pagination.hasNextPage
                      ? 'bg-white text-gray-700 hover:bg-gray-50'
                      : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  }`}
                  aria-label="Next page"
                >
                  <ChevronRight size={20} />
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProviderList;